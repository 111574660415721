<template>
  <div v-if="type === 'edit'" class="tariffs-table">
    <page-block :title="' '" class="vps-detail-backup__block">
      <base-loader v-if="isLoading" class="avatar__loading" />
      <base-table v-else :columns="tableHead" :list="paginList" class="tariffs-table__table">
        <!--          v-for="item in paginList"-->
        <!--          :key="createdSsl.id"-->
        <tariffs-table-ssl-row
          :columns="tableHead"
          :ruleid="ruleid"
          :listener="listener"
          :item="createdSsl"
          @click-more="$emit('click-more', createdSsl)"
        />
      </base-table>
      <NewPagination
        :step="pagin.step"
        :start="pagin.start || 0"
        :count="dataset.length"
        type="base"
        @set-step="setStep"
        @new-pagination="changePagination"
      />
    </page-block>
  </div>
  <div v-else>create</div>
</template>

<script>
import BaseTable from '@/components/Table/BaseTable';
import TariffsTableSslRow from './TariffsTableSslRow';
import NewPagination from '@/components/Pagination/NewPagination';
import forge from 'node-forge';
import { OPTariff } from '@/models/OP/OPTariff';
import showErrorModal from '@/mixins/showErrorModal';
import pagination from '@/mixins/pagination';

export default {
  name: 'RuleSsl',
  components: {
    BaseTable,
    TariffsTableSslRow,
    NewPagination,
  },
  mixins: [showErrorModal, pagination],
  props: {
    dataset: {
      type: Array,
      default: () => [],
    },
    subnetId: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'edit',
      validator: val => ['edit', 'create'].includes(val),
    },
    ruleid: {
      type: String,
      default: '',
    },
    listener: {
      type: OPTariff,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tableHead: [
        {
          key: 'name',
          label: this.$t('tableHead.name'),
          style: {
            width: '280px',
            maxWidth: '400px',
          },
        },
        {
          key: 'domain',
          label: this.$t('tableHead.domain'),
          style: {
            width: '80px',
            maxWidth: '140px',
          },
        },
        {
          key: 'date',
          label: this.$t('tableHead.date'),
          style: {
            width: '180px',
            maxWidth: '200px',
          },
        },
        {
          key: 'status',
          label: this.$t('tableHead.status'),
          style: {
            width: '80px',
          },
        },
        {
          label: this.$t('tableHead.menu'),
          key: 'more',
          style: {
            width: '14px',
            'text-align': 'center',
          },
        },
      ],
      isLoading: true,
      date: '',
      createdSsl: {},
      domain: '',
      /*stepOptions: [10, 25, 50, 100],
      pagin: {
        start: 0,
        step: 10,
      },*/
    };
  },
  computed: {
    containerId() {
      if (this.listener && this.listener.default_tls_container_ref) {
        return this.listener.default_tls_container_ref.split('/containers/')[1];
      } else return '';
    },
    ssl() {
      if (this.containerId.length > 0) {
        const ssl = this.$store.state.moduleStack.containers.find(
          x => x.id === this.containerId
        ).secret_refs;
        const id = ssl.find(x => x.name === 'certificate').secret_ref.split('/secrets/')[1];

        return id;
      } else return '';
    },
    paginList() {
      const { start, step } = this.pagin;
      const end = start + step;
      return this.dataset.slice(start, end);
    },
    showPagination() {
      return this.dataset && this.dataset.length > this.stepOptions[0];
    },
  },
  watch: {
    ssl: {
      handler(event) {
        let payload;
        const ssl = {};
        if (event) {
          this.$store
            .dispatch('moduleStack/getPayloadSecret', event)
            .then(data => {
              payload = data;
              const currentSsl = this.$store.state.moduleStack.containers.find(
                x => x.id === this.containerId
              );
              const pki = forge.pki;
              const date = pki.certificateFromPem(data).validity.notAfter;
              const attr = pki.certificateFromPem(data).subject.attributes;
              ssl.date = this.$d(date);
              ssl.name = currentSsl.name;
              ssl.id = currentSsl.id;

              ssl.status = currentSsl.status;
              ssl.domain = attr.find(x => x.type === '2.5.4.3').value;
            })
            .catch(e => {
              console.log(e);
              this.showError(e);
            })
            .finally(() => {
              this.isLoading = false;
              this.createdSsl = Object.assign(this.createdSsl, ssl);
            });
        }
      },
      immediate: true,
    },
  },
  mounted() {},
  methods: {
    /*setStep(value) {
      this.pagin.step = value;
    },
    changePagination(value) {
      this.pagin.step = value.rows;
      this.pagin.start = value.first;
    },*/
  },
};
</script>

<i18n>
{
  "ru": {
    "tableHead": {
      "name": "Имя",
      "domain": "Домен",
      "date": "Дата окончания",
      "status": "Статус",
      "ip": "IP",
      "menu": "Меню"
    },
    "add" : "Добавить сервер",
    "activeUnder": "До {date}",
    "selectAll": "Выбрать все",
    "selected": "Выбрано 0 серверов | Выбран 1 сервер | Выбрано {n} сервера | Выбрано {n} серверов",
    "prolong": "Продлить"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.tariffs-table {
  width: 100%;
  margin-top: 2rem;

  &__tools {
    flexy(space-between, center);
    margin-bottom: 1.5rem;

    &-end {
      flexy(flex-end, center);
    }

    &-note {
      margin-right: 2.5rem;
    }
  }

  &__pagination {
    margin-top: 1.5rem;

    +breakpoint(sm-and-up) {
      margin-top: 2.5rem;
    }
  }
}
</style>
