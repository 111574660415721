<template>
  <base-table-row
    class="tariffs-table-item"
    :columns="columns"
    v-bind="item"
    col-class="tariffs-table-item__col"
  >
    <template #name>
      <div class="tariff-table-item__server">
        <router-link
          v-if="serverId"
          :to="{ name: 'Server', params: { serverid: serverId } }"
          class="tariffs-table-item__link medium-text"
        >
          {{ item.name }}
        </router-link>
        <div v-else>
          {{ item.name }}
        </div>
      </div>
    </template>
    <template #ip>
      <div class="tariffs-table-item__ip">
        {{ item.address + ':' + item.protocol_port }}
      </div>
    </template>
    <template #weight>
      <div v-if="!item.backup" class="tariffs-table-item__ip">
        <InputNumber
          v-model="item.weight"
          show-buttons
          mode="decimal"
          :min="1"
          :max="256"
          button-layout="horizontal"
          :input-style="{ 'text-align': 'center', width: '6rem', minWidth: '6rem' }"
          decrement-button-class="p-button-blue"
          increment-button-class="p-button-blue"
          increment-button-icon="pi pi-plus"
          decrement-button-icon="pi pi-minus"
          aria-describedby="value2-help"
          @blur="onBlur(item, $event)"
        />
        <!--          class="p-inputtext"-->
      </div>
      <div v-else>
        <label class="typo__label alert-color medium-title">{{ $t('reserve') }}</label>
        <!--        <main-label color="error" theme="filled" class="layout-title large-text">-->
        <!--          {{ $t('reserve') }}-->
        <!--        </main-label>-->
      </div>
    </template>
    <template #state>
      <i v-if="!complete" class="pi pi-spin pi-spinner" style="font-size: 1.5rem"
    /></template>
    <template #status>
      <div class="tariffs-table-item__ip">
        <main-label :color="statusColor(item.operating_status)" theme="plain">
          {{ $t(`member.${item.operating_status}`) }}
        </main-label>
      </div>
    </template>
    <template #state>
      <div class="tariffs-table-item__ip">
        <main-label :color="statusColor(item.provisioning_status)" theme="plain">
          {{ $t(`member.${item.provisioning_status}`) }}
        </main-label>
      </div>
    </template>
    <template #more>
      <v-popover
        v-click-outside="closePopoverOnOutside"
        placement="left"
        :open="isPopoverOpen"
        trigger="manual"
        popover-class="tariff-table-item__popover"
      >
        <plain-button
          icon="more"
          class="tariffs-table-item__more"
          @click="togglePopoverState"
        /><template #popover>
          <rule-member-context-menu
            :rule="ruleid"
            :tariff="item"
            class="tariff-table-item__context-menu"
        /></template>
      </v-popover>
    </template>
  </base-table-row>
</template>

<script>
import BaseTableRow from '@/components/Table/BaseTableRow';
import RuleMemberContextMenu from './RuleMemberContextMenu';
import popoverFix from '@/mixins/popoverFix';
import InputNumber from 'primevue/inputnumber';
import MainLabel from '@/components/Labels/MainLabel';
export default {
  name: 'TariffsTableMembersRow',
  components: {
    BaseTableRow,
    InputNumber,
    MainLabel,
    RuleMemberContextMenu,
  },
  mixins: [popoverFix],
  props: {
    columns: {
      type: Array,
      required: true,
    },
    ruleid: {
      type: String,
      default: '',
    },
    item: {
      type: Object,
      required: true,
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showButtons: false,
      timerCnt: 0,
      weight: 0,
      startCycling: false,
      complete: true,
    };
  },
  computed: {
    serverId() {
      const id =
        this.item && this.item.tags && this.item.tags.length === 1 ? this.item.tags[0] : '';
      return id;
    },
  },
  watch: {
    'item.provisioning_status': {
      handler: function (event) {
        if (event.toLowerCase().includes('pending_')) {
          if (!this.startCycling) {
            this.timerId = 1;
            this.updateMemberStatus();
          }
        }
      },
      immediate: true,
    },

    startCycling(event) {
      console.log('watchStartCycling', event);
    },
    timerId(event) {
      console.log('watchTimerId', event);
    },
  },
  mounted() {
    this.weight = this.item.weight;
  },
  methods: {
    statusColor(status) {
      switch (status) {
        case 'PENDING_CREATE':
          return 'warning';
        case 'PAUSED':
          return 'error';
        case 'ACTIVE':
          return 'success';
        case 'DELETED':
          return 'error';
        case 'PENDING_UPDATE':
          return 'warning';
        case 'ERROR':
          return 'error';
        case 'ONLINE':
          return 'success';
        default:
          return 'error';
      }
    },

    async updateMemberStatus() {
      this.timerId = setTimeout(() => {
        const payload = {
          ruleId: this.ruleid,
          memberId: this.item.id,
        };
        this.$store
          .dispatch('moduleStack/checkMemberStatus', payload)
          .then(async data => {
            if (data.provisioning_status.includes('PENDING_')) {
              if (this.timerCnt < 5) this.timerCnt += 1;
              await this.updateMemberStatus();
            } else {
              this.timerCnt = 0;
              clearTimeout(this.timerId);
              await this.$store.dispatch('moduleStack/getPoolMembers', { id: this.ruleid });
            }
          })
          .catch(e => {
            console.log(e);
            this.$store.dispatch('moduleStack/getPoolMembers', { id: this.ruleid });
          });
      }, 1000 * 10);
    },

    onBlur(item, event) {
      this.complete = false;
      this.weight = this.item.weight;
      const payload = {
        member: {
          weight: +event.target.value,
        },
        ruleId: this.ruleid,
        memberId: this.item.id,
      };
      this.$store
        .dispatch('moduleStack/updateRuleMember', payload)
        .then(data => {
          if (data && data.weight) {
            this.complete = true;
            this.weight = data.weight;
          }
        })
        .catch(() => {
          this.complete = true;
          this.item.weight = this.weight;
        });
    },
    onChange(item, event) {
      // console.log('change', event.target.value);
    },
    onInput(item, event) {
      if (event > 0 && event <= 100) {
        // console.log('ввод', event);
      }
    },
    onFocus(item, event) {
      // console.log('---<>---');
      // console.log('заход в поле', event.target.value);
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "reserve": "Резервный",
    "sure": {
      "confirm": "Подключить",
      "success": "Подсеть добавлена успешно",
      "fail": "Что-то пошло не так",
      "close": "Хорошо"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.tariffs-table-item {
  font-size: 1rem;
  >>> &__col {
    vertical-align: middle;

    +breakpoint(md-and-down) {
      min-width: min-content;
      max-width: unset!important;
    }

    +breakpoint(ml-and-up) {
      text-overflow: ellipsis;
      overflow: hidden;
      position: relative;
      z-index: 1;
    }

    &:hover {
      background: var(--main-card-bg);
      text-overflow: unset;
      overflow: visible;
      z-index: 2;
    }
  }
  &__sub{
    margin-top: 0.5rem;
  }
  &__link {
    position: relative;
    padding: 0.5em;
    margin: -0.5em;
    color: $primary-color;
    text-decoration: none;
    white-space: nowrap;
    background: var(--main-card-bg);
    &:hover {
      color: darken($primary-color, 20%);
    }

    &:hover::after {
      +breakpoint(ml-and-up) {
        content: '';
        display: block;
        size(2.5em, 100%);
        absolute(0px, 100%);
        background: linear-gradient(to right, var(--main-card-bg) 0%, transparent 100%);
      }
    }
  }

}
</style>

<style lang="stylus">
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.tariff-table-item {
  &__popover {
    &.tooltip[x-placement^="left"] {
      +breakpoint(ms-and-down) {
        margin-right: 0;
      }
    }
    &.tooltip[x-placement^="right"] {
      +breakpoint(ms-and-down) {
        margin-left: 0;
      }
    }
    .popover-arrow {
      +breakpoint(ms-and-down) {
        display: none;
      }
    }
  }

  &__context-menu {
    +breakpoint(ms-and-down) {
      width: calc(100vw - 6rem);
    }
  }
}
</style>
