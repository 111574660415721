<template>
  <div v-if="type === 'edit'" class="tariffs-table">
    <page-block :title="' '" class="vps-detail-backup__block">
      <template #action>
        <plain-button color="primary" nowrap @click="addMember()">
          {{ $t('add') }}
        </plain-button>
      </template>
      <base-loader v-if="isLoading" class="avatar__loading" />
      <base-table v-else :columns="tableHead" :list="paginList" class="tariffs-table__table">
        <tariffs-table-members-row
          v-for="item in paginList"
          :key="item.id"
          :columns="tableHead"
          :ruleid="ruleid"
          :item="item"
          @click-more="$emit('click-more', item)"
        />
      </base-table>
      <NewPagination
        :step="pagin.step"
        :start="pagin.start || 0"
        :count="members.length"
        type="base"
        @set-step="setStep"
        @new-pagination="changePagination"
      />
    </page-block>
  </div>
  <div v-else>create</div>
</template>

<script>
import BaseTable from '@/components/Table/BaseTable';
import TariffsTableMembersRow from './TariffsTableMembersRow';
import NewPagination from '@/components/Pagination/NewPagination';
import newMember from '@/mixins/newMember';
import pagination from '@/mixins/pagination';

export default {
  name: 'RuleServers',
  components: {
    BaseTable,
    TariffsTableMembersRow,
    NewPagination,
  },
  mixins: [newMember, pagination],
  props: {
    dataset: {
      type: Array,
      default: () => [],
    },
    subnetId: {
      type: String,
      required: true,
    },
    rule: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: 'edit',
      validator: val => ['edit', 'create'].includes(val),
    },
    ruleid: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      tableHead: [
        {
          key: 'name',
          label: this.$t('tableHead.name'),
          style: {
            width: '280px',
            maxWidth: '400px',
          },
          // sort: {
          //   prop: 'name',
          //   order: 'asc',
          // },
        },
        {
          key: 'ip',
          label: this.$t('tableHead.ip'),
          style: {
            width: '80px',
            maxWidth: '140px',
            textAlign: 'center',
          },
        },
        {
          key: 'weight',
          label: this.$t('tableHead.weight'),
          style: {
            width: '150px',
            maxWidth: '200px',
            textAlign: 'center',
          },
        },
        {
          key: 'state',
          label: this.$t('tableHead.state'),
          style: {
            width: '80px',
            textAlign: 'center',
            // maxWidth: '25px',
          },
        },
        {
          key: 'status',
          label: this.$t('tableHead.status'),
          style: {
            width: '80px',
            textAlign: 'center',
          },
        },
        {
          label: this.$t('tableHead.menu'),
          key: 'more',
          style: {
            width: '14px',
            textAlign: 'center',
          },
        },
      ],
      isLoading: true,
      /*stepOptions: [10, 25, 50, 100],
      pagin: {
        start: 0,
        step: 10,
      },*/
    };
  },
  computed: {
    members() {
      return this.dataset;
    },
    paginList() {
      const { start, step } = this.pagin;
      const end = start + step;
      return this.dataset.slice(start, end);
    },
    servers() {
      return this.$store.state.moduleStack.servers;
    },
    networks() {
      return this.$store.state.moduleStack.networks
        .filter(x => x['router:external'] === false)
        .filter(x => x.shared === false)
        .map(x => x.id);
    },
    subnets() {
      return this.$store.state.moduleStack.subnets;
    },
    ports() {
      return this.$store.state.moduleStack.ports
        .filter(x => x.device_owner === 'compute:nova')
        .filter(x => !x.name.startsWith('octavia-'))
        .filter(el => {
          return this.modifyServer.some(f => {
            return f.id === el.device_id;
          });
        })
        .map(x => x.getPort(this.subnetId));
    },
    modifyServer() {
      return this.servers.map(x => x.server);
    },
  },
  watch: {
    servers: {
      handler(event) {
        this.isLoading = false;
        event.server;
      },
      immediate: true,
    },
  },
  mounted() {},
  methods: {
    addMember() {
      this.newMember();
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "tableHead": {
      "name": "Имя",
      "weight": "Вес",
      "config": "Конфигурация",
      "status": "Статус",
      "state": "Состояние",
      "ip": "IP",
      "menu": "Меню"
    },
    "success": "Сервер <b>%{server}</b> добавлен в правило <b>%{rule}</b>.",
    "add" : "Добавить сервер",
    "activeUnder": "До {date}",
    "selectAll": "Выбрать все",
    "selected": "Выбрано 0 серверов | Выбран 1 сервер | Выбрано {n} сервера | Выбрано {n} серверов",
    "prolong": "Продлить"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.tariffs-table {
  width: 100%;
  margin-top: 2rem;

  &__tools {
    flexy(space-between, center);
    margin-bottom: 1.5rem;

    &-end {
      flexy(flex-end, center);
    }

    &-note {
      margin-right: 2.5rem;
    }
  }

  &__pagination {
    margin-top: 1.5rem;

    +breakpoint(sm-and-up) {
      margin-top: 2.5rem;
    }
  }
}
</style>
